var citys=[];var areas=[];
var city=[];city.id=0;city.name='無';citys.push(city);
city=[];city.id=1;city.name='台北市';citys.push(city);
var area=[];area.city=1;area.id=2;area.zip=100;area.name='中正區';areas.push(area);
area=[];area.city=1;area.id=3;area.zip=103;area.name='大同區';areas.push(area);
area=[];area.city=1;area.id=4;area.zip=104;area.name='中山區';areas.push(area);
area=[];area.city=1;area.id=5;area.zip=105;area.name='松山區';areas.push(area);
area=[];area.city=1;area.id=6;area.zip=106;area.name='大安區';areas.push(area);
area=[];area.city=1;area.id=7;area.zip=108;area.name='萬華區';areas.push(area);
area=[];area.city=1;area.id=8;area.zip=110;area.name='信義區';areas.push(area);
area=[];area.city=1;area.id=9;area.zip=111;area.name='士林區';areas.push(area);
area=[];area.city=1;area.id=10;area.zip=112;area.name='北投區';areas.push(area);
area=[];area.city=1;area.id=11;area.zip=114;area.name='內湖區';areas.push(area);
area=[];area.city=1;area.id=12;area.zip=115;area.name='南港區';areas.push(area);
area=[];area.city=1;area.id=13;area.zip=116;area.name='文山區';areas.push(area);
city=[];city.id=14;city.name='新北市';citys.push(city);
area=[];area.city=14;area.id=15;area.zip=207;area.name='萬里區';areas.push(area);
area=[];area.city=14;area.id=16;area.zip=208;area.name='金山區';areas.push(area);
area=[];area.city=14;area.id=17;area.zip=220;area.name='板橋區';areas.push(area);
area=[];area.city=14;area.id=18;area.zip=221;area.name='汐止區';areas.push(area);
area=[];area.city=14;area.id=19;area.zip=222;area.name='深坑區';areas.push(area);
area=[];area.city=14;area.id=20;area.zip=223;area.name='石碇區';areas.push(area);
area=[];area.city=14;area.id=21;area.zip=224;area.name='瑞芳區';areas.push(area);
area=[];area.city=14;area.id=22;area.zip=226;area.name='平溪區';areas.push(area);
area=[];area.city=14;area.id=23;area.zip=227;area.name='雙溪區';areas.push(area);
area=[];area.city=14;area.id=24;area.zip=228;area.name='貢寮區';areas.push(area);
area=[];area.city=14;area.id=25;area.zip=231;area.name='新店區';areas.push(area);
area=[];area.city=14;area.id=26;area.zip=232;area.name='坪林區';areas.push(area);
area=[];area.city=14;area.id=27;area.zip=233;area.name='烏來區';areas.push(area);
area=[];area.city=14;area.id=28;area.zip=234;area.name='永和區';areas.push(area);
area=[];area.city=14;area.id=29;area.zip=235;area.name='中和區';areas.push(area);
area=[];area.city=14;area.id=30;area.zip=236;area.name='土城區';areas.push(area);
area=[];area.city=14;area.id=31;area.zip=237;area.name='三峽區';areas.push(area);
area=[];area.city=14;area.id=32;area.zip=238;area.name='樹林區';areas.push(area);
area=[];area.city=14;area.id=33;area.zip=239;area.name='鶯歌區';areas.push(area);
area=[];area.city=14;area.id=34;area.zip=241;area.name='三重區';areas.push(area);
area=[];area.city=14;area.id=35;area.zip=242;area.name='新莊區';areas.push(area);
area=[];area.city=14;area.id=36;area.zip=243;area.name='泰山區';areas.push(area);
area=[];area.city=14;area.id=37;area.zip=244;area.name='林口區';areas.push(area);
area=[];area.city=14;area.id=38;area.zip=247;area.name='蘆洲區';areas.push(area);
area=[];area.city=14;area.id=39;area.zip=248;area.name='五股區';areas.push(area);
area=[];area.city=14;area.id=40;area.zip=249;area.name='八里區';areas.push(area);
area=[];area.city=14;area.id=41;area.zip=251;area.name='淡水區';areas.push(area);
area=[];area.city=14;area.id=42;area.zip=252;area.name='三芝區';areas.push(area);
area=[];area.city=14;area.id=43;area.zip=253;area.name='石門區';areas.push(area);
city=[];city.id=44;city.name='基隆市';citys.push(city);
area=[];area.city=44;area.id=45;area.zip=200;area.name='仁愛區';areas.push(area);
area=[];area.city=44;area.id=46;area.zip=201;area.name='信義區';areas.push(area);
area=[];area.city=44;area.id=47;area.zip=202;area.name='中正區';areas.push(area);
area=[];area.city=44;area.id=48;area.zip=203;area.name='中山區';areas.push(area);
area=[];area.city=44;area.id=49;area.zip=204;area.name='安樂區';areas.push(area);
area=[];area.city=44;area.id=50;area.zip=205;area.name='暖暖區';areas.push(area);
area=[];area.city=44;area.id=51;area.zip=206;area.name='七堵區';areas.push(area);
city=[];city.id=52;city.name='桃園市';citys.push(city);
area=[];area.city=52;area.id=53;area.zip=320;area.name='中壢區';areas.push(area);
area=[];area.city=52;area.id=54;area.zip=324;area.name='平鎮區';areas.push(area);
area=[];area.city=52;area.id=55;area.zip=325;area.name='龍潭區';areas.push(area);
area=[];area.city=52;area.id=56;area.zip=326;area.name='楊梅區';areas.push(area);
area=[];area.city=52;area.id=57;area.zip=327;area.name='新屋區';areas.push(area);
area=[];area.city=52;area.id=58;area.zip=328;area.name='觀音區';areas.push(area);
area=[];area.city=52;area.id=59;area.zip=330;area.name='桃園區';areas.push(area);
area=[];area.city=52;area.id=60;area.zip=333;area.name='龜山區';areas.push(area);
area=[];area.city=52;area.id=61;area.zip=334;area.name='八德區';areas.push(area);
area=[];area.city=52;area.id=62;area.zip=335;area.name='大溪區';areas.push(area);
area=[];area.city=52;area.id=63;area.zip=336;area.name='復興區';areas.push(area);
area=[];area.city=52;area.id=64;area.zip=337;area.name='大園區';areas.push(area);
area=[];area.city=52;area.id=65;area.zip=338;area.name='蘆竹區';areas.push(area);
city=[];city.id=66;city.name='新竹市';citys.push(city);
area=[];area.city=66;area.id=67;area.zip=300;area.name='東區';areas.push(area);
area=[];area.city=66;area.id=68;area.zip=300;area.name='北區';areas.push(area);
area=[];area.city=66;area.id=69;area.zip=300;area.name='香山區';areas.push(area);
city=[];city.id=70;city.name='新竹縣';citys.push(city);
area=[];area.city=70;area.id=71;area.zip=302;area.name='竹北市';areas.push(area);
area=[];area.city=70;area.id=72;area.zip=303;area.name='湖口鄉';areas.push(area);
area=[];area.city=70;area.id=73;area.zip=304;area.name='新豐鄉';areas.push(area);
area=[];area.city=70;area.id=74;area.zip=305;area.name='新埔鎮';areas.push(area);
area=[];area.city=70;area.id=75;area.zip=306;area.name='關西鎮';areas.push(area);
area=[];area.city=70;area.id=76;area.zip=307;area.name='芎林鄉';areas.push(area);
area=[];area.city=70;area.id=77;area.zip=308;area.name='寶山鄉';areas.push(area);
area=[];area.city=70;area.id=78;area.zip=310;area.name='竹東鎮';areas.push(area);
area=[];area.city=70;area.id=79;area.zip=311;area.name='五峰鄉';areas.push(area);
area=[];area.city=70;area.id=80;area.zip=312;area.name='橫山鄉';areas.push(area);
area=[];area.city=70;area.id=81;area.zip=313;area.name='尖石鄉';areas.push(area);
area=[];area.city=70;area.id=82;area.zip=314;area.name='北埔鄉';areas.push(area);
area=[];area.city=70;area.id=83;area.zip=315;area.name='峨眉鄉';areas.push(area);
city=[];city.id=84;city.name='苗栗縣';citys.push(city);
area=[];area.city=84;area.id=85;area.zip=350;area.name='竹南鎮';areas.push(area);
area=[];area.city=84;area.id=86;area.zip=351;area.name='頭份鎮';areas.push(area);
area=[];area.city=84;area.id=87;area.zip=352;area.name='三灣鄉';areas.push(area);
area=[];area.city=84;area.id=88;area.zip=353;area.name='南庄鄉';areas.push(area);
area=[];area.city=84;area.id=89;area.zip=354;area.name='獅潭鄉';areas.push(area);
area=[];area.city=84;area.id=90;area.zip=356;area.name='後龍鎮';areas.push(area);
area=[];area.city=84;area.id=91;area.zip=357;area.name='通霄鎮';areas.push(area);
area=[];area.city=84;area.id=92;area.zip=358;area.name='苑裡鎮';areas.push(area);
area=[];area.city=84;area.id=93;area.zip=360;area.name='苗栗市';areas.push(area);
area=[];area.city=84;area.id=94;area.zip=361;area.name='造橋鄉';areas.push(area);
area=[];area.city=84;area.id=95;area.zip=362;area.name='頭屋鄉';areas.push(area);
area=[];area.city=84;area.id=96;area.zip=363;area.name='公館鄉';areas.push(area);
area=[];area.city=84;area.id=97;area.zip=364;area.name='大湖鄉';areas.push(area);
area=[];area.city=84;area.id=98;area.zip=365;area.name='泰安鄉';areas.push(area);
area=[];area.city=84;area.id=99;area.zip=366;area.name='銅鑼鄉';areas.push(area);
area=[];area.city=84;area.id=100;area.zip=367;area.name='三義鄉';areas.push(area);
area=[];area.city=84;area.id=101;area.zip=368;area.name='西湖鄉';areas.push(area);
area=[];area.city=84;area.id=102;area.zip=369;area.name='卓蘭鎮';areas.push(area);
city=[];city.id=103;city.name='台中市';citys.push(city);
area=[];area.city=103;area.id=104;area.zip=400;area.name='中區';areas.push(area);
area=[];area.city=103;area.id=105;area.zip=401;area.name='東區';areas.push(area);
area=[];area.city=103;area.id=106;area.zip=402;area.name='南區';areas.push(area);
area=[];area.city=103;area.id=107;area.zip=403;area.name='西區';areas.push(area);
area=[];area.city=103;area.id=108;area.zip=404;area.name='北區';areas.push(area);
area=[];area.city=103;area.id=109;area.zip=406;area.name='北屯區';areas.push(area);
area=[];area.city=103;area.id=110;area.zip=407;area.name='西屯區';areas.push(area);
area=[];area.city=103;area.id=111;area.zip=408;area.name='南屯區';areas.push(area);
area=[];area.city=103;area.id=113;area.zip=411;area.name='太平區';areas.push(area);
area=[];area.city=103;area.id=114;area.zip=412;area.name='大里區';areas.push(area);
area=[];area.city=103;area.id=115;area.zip=413;area.name='霧峰區';areas.push(area);
area=[];area.city=103;area.id=116;area.zip=414;area.name='烏日區';areas.push(area);
area=[];area.city=103;area.id=117;area.zip=420;area.name='豐原區';areas.push(area);
area=[];area.city=103;area.id=118;area.zip=421;area.name='后里區';areas.push(area);
area=[];area.city=103;area.id=119;area.zip=422;area.name='石岡區';areas.push(area);
area=[];area.city=103;area.id=120;area.zip=423;area.name='東勢區';areas.push(area);
area=[];area.city=103;area.id=121;area.zip=424;area.name='和平區';areas.push(area);
area=[];area.city=103;area.id=122;area.zip=426;area.name='新社區';areas.push(area);
area=[];area.city=103;area.id=123;area.zip=427;area.name='潭子區';areas.push(area);
area=[];area.city=103;area.id=124;area.zip=428;area.name='大雅區';areas.push(area);
area=[];area.city=103;area.id=125;area.zip=429;area.name='神岡區';areas.push(area);
area=[];area.city=103;area.id=126;area.zip=432;area.name='大肚區';areas.push(area);
area=[];area.city=103;area.id=127;area.zip=433;area.name='沙鹿區';areas.push(area);
area=[];area.city=103;area.id=128;area.zip=434;area.name='龍井區';areas.push(area);
area=[];area.city=103;area.id=129;area.zip=435;area.name='梧棲區';areas.push(area);
area=[];area.city=103;area.id=130;area.zip=436;area.name='清水區';areas.push(area);
area=[];area.city=103;area.id=131;area.zip=437;area.name='大甲區';areas.push(area);
area=[];area.city=103;area.id=132;area.zip=438;area.name='外埔區';areas.push(area);
area=[];area.city=103;area.id=133;area.zip=439;area.name='大安區';areas.push(area);
city=[];city.id=134;city.name='彰化縣';citys.push(city);
area=[];area.city=134;area.id=135;area.zip=500;area.name='彰化市';areas.push(area);
area=[];area.city=134;area.id=136;area.zip=502;area.name='芬園鄉';areas.push(area);
area=[];area.city=134;area.id=137;area.zip=503;area.name='花壇鄉';areas.push(area);
area=[];area.city=134;area.id=138;area.zip=504;area.name='秀水鄉';areas.push(area);
area=[];area.city=134;area.id=139;area.zip=505;area.name='鹿港鎮';areas.push(area);
area=[];area.city=134;area.id=140;area.zip=506;area.name='福興鄉';areas.push(area);
area=[];area.city=134;area.id=141;area.zip=507;area.name='線西鄉';areas.push(area);
area=[];area.city=134;area.id=142;area.zip=508;area.name='和美鎮';areas.push(area);
area=[];area.city=134;area.id=143;area.zip=509;area.name='伸港鄉';areas.push(area);
area=[];area.city=134;area.id=144;area.zip=510;area.name='員林鎮';areas.push(area);
area=[];area.city=134;area.id=145;area.zip=511;area.name='社頭鄉';areas.push(area);
area=[];area.city=134;area.id=146;area.zip=512;area.name='永靖鄉';areas.push(area);
area=[];area.city=134;area.id=147;area.zip=513;area.name='埔心鄉';areas.push(area);
area=[];area.city=134;area.id=148;area.zip=514;area.name='溪湖鎮';areas.push(area);
area=[];area.city=134;area.id=149;area.zip=515;area.name='大村鄉';areas.push(area);
area=[];area.city=134;area.id=150;area.zip=516;area.name='埔鹽鄉';areas.push(area);
area=[];area.city=134;area.id=151;area.zip=520;area.name='田中鎮';areas.push(area);
area=[];area.city=134;area.id=152;area.zip=521;area.name='北斗鎮';areas.push(area);
area=[];area.city=134;area.id=153;area.zip=522;area.name='田尾鄉';areas.push(area);
area=[];area.city=134;area.id=154;area.zip=523;area.name='埤頭鄉';areas.push(area);
area=[];area.city=134;area.id=155;area.zip=524;area.name='溪州鄉';areas.push(area);
area=[];area.city=134;area.id=156;area.zip=525;area.name='竹塘鄉';areas.push(area);
area=[];area.city=134;area.id=157;area.zip=526;area.name='二林鎮';areas.push(area);
area=[];area.city=134;area.id=158;area.zip=527;area.name='大城鄉';areas.push(area);
area=[];area.city=134;area.id=159;area.zip=528;area.name='芳苑鄉';areas.push(area);
area=[];area.city=134;area.id=160;area.zip=530;area.name='二水鄉';areas.push(area);
city=[];city.id=161;city.name='南投縣';citys.push(city);
area=[];area.city=161;area.id=162;area.zip=540;area.name='南投市';areas.push(area);
area=[];area.city=161;area.id=163;area.zip=541;area.name='中寮鄉';areas.push(area);
area=[];area.city=161;area.id=164;area.zip=542;area.name='草屯鎮';areas.push(area);
area=[];area.city=161;area.id=165;area.zip=544;area.name='國姓鄉';areas.push(area);
area=[];area.city=161;area.id=166;area.zip=545;area.name='埔里鎮';areas.push(area);
area=[];area.city=161;area.id=167;area.zip=546;area.name='仁愛鄉';areas.push(area);
area=[];area.city=161;area.id=168;area.zip=551;area.name='名間鄉';areas.push(area);
area=[];area.city=161;area.id=169;area.zip=552;area.name='集集鎮';areas.push(area);
area=[];area.city=161;area.id=170;area.zip=553;area.name='水里鄉';areas.push(area);
area=[];area.city=161;area.id=171;area.zip=555;area.name='魚池鄉';areas.push(area);
area=[];area.city=161;area.id=172;area.zip=556;area.name='信義鄉';areas.push(area);
area=[];area.city=161;area.id=173;area.zip=557;area.name='竹山鎮';areas.push(area);
area=[];area.city=161;area.id=174;area.zip=558;area.name='鹿谷鄉';areas.push(area);
city=[];city.id=175;city.name='雲林縣';citys.push(city);
area=[];area.city=175;area.id=176;area.zip=630;area.name='斗南鎮';areas.push(area);
area=[];area.city=175;area.id=177;area.zip=631;area.name='大埤鄉';areas.push(area);
area=[];area.city=175;area.id=178;area.zip=632;area.name='虎尾鎮';areas.push(area);
area=[];area.city=175;area.id=179;area.zip=633;area.name='土庫鎮';areas.push(area);
area=[];area.city=175;area.id=180;area.zip=634;area.name='褒忠鄉';areas.push(area);
area=[];area.city=175;area.id=181;area.zip=635;area.name='東勢鄉';areas.push(area);
area=[];area.city=175;area.id=182;area.zip=636;area.name='台西鄉';areas.push(area);
area=[];area.city=175;area.id=183;area.zip=637;area.name='崙背鄉';areas.push(area);
area=[];area.city=175;area.id=184;area.zip=638;area.name='麥寮鄉';areas.push(area);
area=[];area.city=175;area.id=185;area.zip=640;area.name='斗六市';areas.push(area);
area=[];area.city=175;area.id=186;area.zip=643;area.name='林內鄉';areas.push(area);
area=[];area.city=175;area.id=187;area.zip=646;area.name='古坑鄉';areas.push(area);
area=[];area.city=175;area.id=188;area.zip=647;area.name='莿桐鄉';areas.push(area);
area=[];area.city=175;area.id=189;area.zip=648;area.name='西螺鎮';areas.push(area);
area=[];area.city=175;area.id=190;area.zip=649;area.name='二崙鄉';areas.push(area);
area=[];area.city=175;area.id=191;area.zip=651;area.name='北港鎮';areas.push(area);
area=[];area.city=175;area.id=192;area.zip=652;area.name='水林鄉';areas.push(area);
area=[];area.city=175;area.id=193;area.zip=653;area.name='口湖鄉';areas.push(area);
area=[];area.city=175;area.id=194;area.zip=654;area.name='四湖鄉';areas.push(area);
area=[];area.city=175;area.id=195;area.zip=655;area.name='元長鄉';areas.push(area);
city=[];city.id=196;city.name='嘉義市';citys.push(city);
area=[];area.city=196;area.id=197;area.zip=600;area.name='東區';areas.push(area);
area=[];area.city=196;area.id=198;area.zip=600;area.name='西區';areas.push(area);
city=[];city.id=199;city.name='嘉義縣';citys.push(city);
area=[];area.city=199;area.id=200;area.zip=602;area.name='番路鄉';areas.push(area);
area=[];area.city=199;area.id=201;area.zip=603;area.name='梅山鄉';areas.push(area);
area=[];area.city=199;area.id=202;area.zip=604;area.name='竹崎鄉';areas.push(area);
area=[];area.city=199;area.id=203;area.zip=605;area.name='阿里山鄉';areas.push(area);
area=[];area.city=199;area.id=204;area.zip=606;area.name='中埔鄉';areas.push(area);
area=[];area.city=199;area.id=205;area.zip=607;area.name='大埔鄉';areas.push(area);
area=[];area.city=199;area.id=206;area.zip=608;area.name='水上鄉';areas.push(area);
area=[];area.city=199;area.id=207;area.zip=611;area.name='鹿草鄉';areas.push(area);
area=[];area.city=199;area.id=208;area.zip=612;area.name='太保市';areas.push(area);
area=[];area.city=199;area.id=209;area.zip=613;area.name='朴子市';areas.push(area);
area=[];area.city=199;area.id=210;area.zip=614;area.name='東石鄉';areas.push(area);
area=[];area.city=199;area.id=211;area.zip=615;area.name='六腳鄉';areas.push(area);
area=[];area.city=199;area.id=212;area.zip=616;area.name='新港鄉';areas.push(area);
area=[];area.city=199;area.id=213;area.zip=621;area.name='民雄鄉';areas.push(area);
area=[];area.city=199;area.id=214;area.zip=622;area.name='大林鎮';areas.push(area);
area=[];area.city=199;area.id=215;area.zip=623;area.name='溪口鄉';areas.push(area);
area=[];area.city=199;area.id=216;area.zip=624;area.name='義竹鄉';areas.push(area);
area=[];area.city=199;area.id=217;area.zip=625;area.name='布袋鎮';areas.push(area);
city=[];city.id=218;city.name='台南市';citys.push(city);
area=[];area.city=218;area.id=219;area.zip=700;area.name='中西區';areas.push(area);
area=[];area.city=218;area.id=220;area.zip=701;area.name='東區';areas.push(area);
area=[];area.city=218;area.id=221;area.zip=702;area.name='南區';areas.push(area);
area=[];area.city=218;area.id=222;area.zip=704;area.name='北區';areas.push(area);
area=[];area.city=218;area.id=223;area.zip=708;area.name='安平區';areas.push(area);
area=[];area.city=218;area.id=224;area.zip=709;area.name='安南區';areas.push(area);
area=[];area.city=218;area.id=226;area.zip=710;area.name='永康區';areas.push(area);
area=[];area.city=218;area.id=227;area.zip=711;area.name='歸仁區';areas.push(area);
area=[];area.city=218;area.id=228;area.zip=712;area.name='新化區';areas.push(area);
area=[];area.city=218;area.id=229;area.zip=713;area.name='左鎮區';areas.push(area);
area=[];area.city=218;area.id=230;area.zip=714;area.name='玉井區';areas.push(area);
area=[];area.city=218;area.id=231;area.zip=715;area.name='楠西區';areas.push(area);
area=[];area.city=218;area.id=232;area.zip=716;area.name='南化區';areas.push(area);
area=[];area.city=218;area.id=233;area.zip=717;area.name='仁德區';areas.push(area);
area=[];area.city=218;area.id=234;area.zip=718;area.name='關廟區';areas.push(area);
area=[];area.city=218;area.id=235;area.zip=719;area.name='龍崎區';areas.push(area);
area=[];area.city=218;area.id=236;area.zip=720;area.name='官田區';areas.push(area);
area=[];area.city=218;area.id=237;area.zip=721;area.name='麻豆區';areas.push(area);
area=[];area.city=218;area.id=238;area.zip=722;area.name='佳里區';areas.push(area);
area=[];area.city=218;area.id=239;area.zip=723;area.name='西港區';areas.push(area);
area=[];area.city=218;area.id=240;area.zip=724;area.name='七股區';areas.push(area);
area=[];area.city=218;area.id=241;area.zip=725;area.name='將軍區';areas.push(area);
area=[];area.city=218;area.id=242;area.zip=726;area.name='學甲區';areas.push(area);
area=[];area.city=218;area.id=243;area.zip=727;area.name='北門區';areas.push(area);
area=[];area.city=218;area.id=244;area.zip=730;area.name='新營區';areas.push(area);
area=[];area.city=218;area.id=245;area.zip=731;area.name='後壁區';areas.push(area);
area=[];area.city=218;area.id=246;area.zip=732;area.name='白河區';areas.push(area);
area=[];area.city=218;area.id=247;area.zip=733;area.name='東山區';areas.push(area);
area=[];area.city=218;area.id=248;area.zip=734;area.name='六甲區';areas.push(area);
area=[];area.city=218;area.id=249;area.zip=735;area.name='下營區';areas.push(area);
area=[];area.city=218;area.id=250;area.zip=736;area.name='柳營區';areas.push(area);
area=[];area.city=218;area.id=251;area.zip=737;area.name='鹽水區';areas.push(area);
area=[];area.city=218;area.id=252;area.zip=741;area.name='善化區';areas.push(area);
area=[];area.city=218;area.id=253;area.zip=742;area.name='大內區';areas.push(area);
area=[];area.city=218;area.id=254;area.zip=743;area.name='山上區';areas.push(area);
area=[];area.city=218;area.id=255;area.zip=744;area.name='新市區';areas.push(area);
area=[];area.city=218;area.id=256;area.zip=745;area.name='安定區';areas.push(area);
city=[];city.id=257;city.name='高雄市';citys.push(city);
area=[];area.city=257;area.id=258;area.zip=800;area.name='新興區';areas.push(area);
area=[];area.city=257;area.id=259;area.zip=801;area.name='前金區';areas.push(area);
area=[];area.city=257;area.id=260;area.zip=802;area.name='苓雅區';areas.push(area);
area=[];area.city=257;area.id=261;area.zip=803;area.name='鹽埕區';areas.push(area);
area=[];area.city=257;area.id=262;area.zip=804;area.name='鼓山區';areas.push(area);
area=[];area.city=257;area.id=263;area.zip=805;area.name='旗津區';areas.push(area);
area=[];area.city=257;area.id=264;area.zip=806;area.name='前鎮區';areas.push(area);
area=[];area.city=257;area.id=265;area.zip=807;area.name='三民區';areas.push(area);
area=[];area.city=257;area.id=266;area.zip=811;area.name='楠梓區';areas.push(area);
area=[];area.city=257;area.id=267;area.zip=812;area.name='小港區';areas.push(area);
area=[];area.city=257;area.id=268;area.zip=813;area.name='左營區';areas.push(area);
area=[];area.city=257;area.id=270;area.zip=814;area.name='仁武區';areas.push(area);
area=[];area.city=257;area.id=271;area.zip=815;area.name='大社區';areas.push(area);
area=[];area.city=257;area.id=272;area.zip=820;area.name='岡山區';areas.push(area);
area=[];area.city=257;area.id=273;area.zip=821;area.name='路竹區';areas.push(area);
area=[];area.city=257;area.id=274;area.zip=822;area.name='阿蓮區';areas.push(area);
area=[];area.city=257;area.id=275;area.zip=823;area.name='田寮區';areas.push(area);
area=[];area.city=257;area.id=276;area.zip=824;area.name='燕巢區';areas.push(area);
area=[];area.city=257;area.id=277;area.zip=825;area.name='橋頭區';areas.push(area);
area=[];area.city=257;area.id=278;area.zip=826;area.name='梓官區';areas.push(area);
area=[];area.city=257;area.id=279;area.zip=827;area.name='彌陀區';areas.push(area);
area=[];area.city=257;area.id=280;area.zip=828;area.name='永安區';areas.push(area);
area=[];area.city=257;area.id=281;area.zip=829;area.name='湖內區';areas.push(area);
area=[];area.city=257;area.id=282;area.zip=830;area.name='鳳山區';areas.push(area);
area=[];area.city=257;area.id=283;area.zip=831;area.name='大寮區';areas.push(area);
area=[];area.city=257;area.id=284;area.zip=832;area.name='林園區';areas.push(area);
area=[];area.city=257;area.id=285;area.zip=833;area.name='鳥松區';areas.push(area);
area=[];area.city=257;area.id=286;area.zip=840;area.name='大樹區';areas.push(area);
area=[];area.city=257;area.id=287;area.zip=842;area.name='旗山區';areas.push(area);
area=[];area.city=257;area.id=288;area.zip=843;area.name='美濃區';areas.push(area);
area=[];area.city=257;area.id=289;area.zip=844;area.name='六龜區';areas.push(area);
area=[];area.city=257;area.id=290;area.zip=845;area.name='內門區';areas.push(area);
area=[];area.city=257;area.id=291;area.zip=846;area.name='杉林區';areas.push(area);
area=[];area.city=257;area.id=292;area.zip=847;area.name='甲仙區';areas.push(area);
area=[];area.city=257;area.id=293;area.zip=848;area.name='桃源區';areas.push(area);
area=[];area.city=257;area.id=294;area.zip=849;area.name='那瑪夏區';areas.push(area);
area=[];area.city=257;area.id=295;area.zip=851;area.name='茂林區';areas.push(area);
area=[];area.city=257;area.id=296;area.zip=852;area.name='茄萣區';areas.push(area);
city=[];city.id=297;city.name='屏東縣';citys.push(city);
area=[];area.city=297;area.id=298;area.zip=900;area.name='屏東市';areas.push(area);
area=[];area.city=297;area.id=299;area.zip=901;area.name='三地門鄉';areas.push(area);
area=[];area.city=297;area.id=300;area.zip=902;area.name='霧台鄉';areas.push(area);
area=[];area.city=297;area.id=301;area.zip=903;area.name='瑪家鄉';areas.push(area);
area=[];area.city=297;area.id=302;area.zip=904;area.name='九如鄉';areas.push(area);
area=[];area.city=297;area.id=303;area.zip=905;area.name='里港鄉';areas.push(area);
area=[];area.city=297;area.id=304;area.zip=906;area.name='高樹鄉';areas.push(area);
area=[];area.city=297;area.id=305;area.zip=907;area.name='鹽埔鄉';areas.push(area);
area=[];area.city=297;area.id=306;area.zip=908;area.name='長治鄉';areas.push(area);
area=[];area.city=297;area.id=307;area.zip=909;area.name='麟洛鄉';areas.push(area);
area=[];area.city=297;area.id=308;area.zip=911;area.name='竹田鄉';areas.push(area);
area=[];area.city=297;area.id=309;area.zip=912;area.name='內埔鄉';areas.push(area);
area=[];area.city=297;area.id=310;area.zip=913;area.name='萬丹鄉';areas.push(area);
area=[];area.city=297;area.id=311;area.zip=920;area.name='潮州鎮';areas.push(area);
area=[];area.city=297;area.id=312;area.zip=921;area.name='泰武鄉';areas.push(area);
area=[];area.city=297;area.id=313;area.zip=922;area.name='來義鄉';areas.push(area);
area=[];area.city=297;area.id=314;area.zip=923;area.name='萬巒鄉';areas.push(area);
area=[];area.city=297;area.id=315;area.zip=924;area.name='崁頂鄉';areas.push(area);
area=[];area.city=297;area.id=316;area.zip=925;area.name='新埤鄉';areas.push(area);
area=[];area.city=297;area.id=317;area.zip=926;area.name='南州鄉';areas.push(area);
area=[];area.city=297;area.id=318;area.zip=927;area.name='林邊鄉';areas.push(area);
area=[];area.city=297;area.id=319;area.zip=928;area.name='東港鎮';areas.push(area);
area=[];area.city=297;area.id=320;area.zip=929;area.name='琉球鄉';areas.push(area);
area=[];area.city=297;area.id=321;area.zip=931;area.name='佳冬鄉';areas.push(area);
area=[];area.city=297;area.id=322;area.zip=932;area.name='新園鄉';areas.push(area);
area=[];area.city=297;area.id=323;area.zip=940;area.name='枋寮鄉';areas.push(area);
area=[];area.city=297;area.id=324;area.zip=941;area.name='枋山鄉';areas.push(area);
area=[];area.city=297;area.id=325;area.zip=942;area.name='春日鄉';areas.push(area);
area=[];area.city=297;area.id=326;area.zip=943;area.name='獅子鄉';areas.push(area);
area=[];area.city=297;area.id=327;area.zip=944;area.name='車城鄉';areas.push(area);
area=[];area.city=297;area.id=328;area.zip=945;area.name='牡丹鄉';areas.push(area);
area=[];area.city=297;area.id=329;area.zip=946;area.name='恆春鎮';areas.push(area);
area=[];area.city=297;area.id=330;area.zip=947;area.name='滿洲鄉';areas.push(area);
city=[];city.id=331;city.name='台東縣';citys.push(city);
area=[];area.city=331;area.id=332;area.zip=950;area.name='台東市';areas.push(area);
area=[];area.city=331;area.id=333;area.zip=951;area.name='綠島鄉';areas.push(area);
area=[];area.city=331;area.id=334;area.zip=952;area.name='蘭嶼鄉';areas.push(area);
area=[];area.city=331;area.id=335;area.zip=953;area.name='延平鄉';areas.push(area);
area=[];area.city=331;area.id=336;area.zip=954;area.name='卑南鄉';areas.push(area);
area=[];area.city=331;area.id=337;area.zip=955;area.name='鹿野鄉';areas.push(area);
area=[];area.city=331;area.id=338;area.zip=956;area.name='關山鎮';areas.push(area);
area=[];area.city=331;area.id=339;area.zip=957;area.name='海端鄉';areas.push(area);
area=[];area.city=331;area.id=340;area.zip=958;area.name='池上鄉';areas.push(area);
area=[];area.city=331;area.id=341;area.zip=959;area.name='東河鄉';areas.push(area);
area=[];area.city=331;area.id=342;area.zip=961;area.name='成功鎮';areas.push(area);
area=[];area.city=331;area.id=343;area.zip=962;area.name='長濱鄉';areas.push(area);
area=[];area.city=331;area.id=344;area.zip=963;area.name='太麻里鄉';areas.push(area);
area=[];area.city=331;area.id=345;area.zip=964;area.name='金峰鄉';areas.push(area);
area=[];area.city=331;area.id=346;area.zip=965;area.name='大武鄉';areas.push(area);
area=[];area.city=331;area.id=347;area.zip=966;area.name='達仁鄉';areas.push(area);
city=[];city.id=348;city.name='花蓮縣';citys.push(city);
area=[];area.city=348;area.id=349;area.zip=970;area.name='花蓮市';areas.push(area);
area=[];area.city=348;area.id=350;area.zip=971;area.name='新城鄉';areas.push(area);
area=[];area.city=348;area.id=351;area.zip=972;area.name='秀林鄉';areas.push(area);
area=[];area.city=348;area.id=352;area.zip=973;area.name='吉安鄉';areas.push(area);
area=[];area.city=348;area.id=353;area.zip=974;area.name='壽豐鄉';areas.push(area);
area=[];area.city=348;area.id=354;area.zip=975;area.name='鳳林鎮';areas.push(area);
area=[];area.city=348;area.id=355;area.zip=976;area.name='光復鄉';areas.push(area);
area=[];area.city=348;area.id=356;area.zip=977;area.name='豐濱鄉';areas.push(area);
area=[];area.city=348;area.id=357;area.zip=978;area.name='瑞穗鄉';areas.push(area);
area=[];area.city=348;area.id=358;area.zip=979;area.name='萬榮鄉';areas.push(area);
area=[];area.city=348;area.id=359;area.zip=981;area.name='玉里鎮';areas.push(area);
area=[];area.city=348;area.id=360;area.zip=982;area.name='卓溪鄉';areas.push(area);
area=[];area.city=348;area.id=361;area.zip=983;area.name='富里鄉';areas.push(area);
city=[];city.id=362;city.name='宜蘭縣';citys.push(city);
area=[];area.city=362;area.id=363;area.zip=260;area.name='宜蘭市';areas.push(area);
area=[];area.city=362;area.id=364;area.zip=261;area.name='頭城鎮';areas.push(area);
area=[];area.city=362;area.id=365;area.zip=262;area.name='礁溪鄉';areas.push(area);
area=[];area.city=362;area.id=366;area.zip=263;area.name='壯圍鄉';areas.push(area);
area=[];area.city=362;area.id=367;area.zip=264;area.name='員山鄉';areas.push(area);
area=[];area.city=362;area.id=368;area.zip=265;area.name='羅東鎮';areas.push(area);
area=[];area.city=362;area.id=369;area.zip=266;area.name='三星鄉';areas.push(area);
area=[];area.city=362;area.id=370;area.zip=267;area.name='大同鄉';areas.push(area);
area=[];area.city=362;area.id=371;area.zip=268;area.name='五結鄉';areas.push(area);
area=[];area.city=362;area.id=372;area.zip=269;area.name='冬山鄉';areas.push(area);
area=[];area.city=362;area.id=373;area.zip=270;area.name='蘇澳鎮';areas.push(area);
area=[];area.city=362;area.id=374;area.zip=272;area.name='南澳鄉';areas.push(area);
city=[];city.id=375;city.name='澎湖縣';citys.push(city);
area=[];area.city=375;area.id=376;area.zip=880;area.name='馬公市';areas.push(area);
area=[];area.city=375;area.id=377;area.zip=881;area.name='西嶼鄉';areas.push(area);
area=[];area.city=375;area.id=378;area.zip=882;area.name='望安鄉';areas.push(area);
area=[];area.city=375;area.id=379;area.zip=883;area.name='七美鄉';areas.push(area);
area=[];area.city=375;area.id=380;area.zip=884;area.name='白沙鄉';areas.push(area);
area=[];area.city=375;area.id=381;area.zip=885;area.name='湖西鄉';areas.push(area);
city=[];city.id=382;city.name='金門縣';citys.push(city);
area=[];area.city=382;area.id=383;area.zip=890;area.name='金沙鎮';areas.push(area);
area=[];area.city=382;area.id=384;area.zip=891;area.name='金湖鎮';areas.push(area);
area=[];area.city=382;area.id=385;area.zip=892;area.name='金寧鄉';areas.push(area);
area=[];area.city=382;area.id=386;area.zip=893;area.name='金城鎮';areas.push(area);
area=[];area.city=382;area.id=387;area.zip=894;area.name='烈嶼鄉';areas.push(area);
area=[];area.city=382;area.id=388;area.zip=896;area.name='烏坵鄉';areas.push(area);
city=[];city.id=389;city.name='連江縣';citys.push(city);
area=[];area.city=389;area.id=390;area.zip=209;area.name='南竿鄉';areas.push(area);
area=[];area.city=389;area.id=391;area.zip=210;area.name='北竿鄉';areas.push(area);
area=[];area.city=389;area.id=392;area.zip=211;area.name='莒光鄉';areas.push(area);
area=[];area.city=389;area.id=393;area.zip=212;area.name='東引鄉';areas.push(area);
city=[];city.id=395;city.name='全省';citys.push(city);

export {citys, areas}