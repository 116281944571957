
function Index() {
    return (
        <>
            <div className="text-MyWhite">Admin</div>
        </>
    )
}

export default Index
